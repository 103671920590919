import React from "react";
import { Link } from "gatsby";
import { Article } from "../models/article.model";
import { getNewsArticleUrl } from "../utils/link.helper";
import MAnchor from "./MAnchor";
import DocSample from "../images/doc.png";

interface Props {
  article: Article;
}

const NewsItem: React.FC<Props> = (props: Props) => {
  const { article } = props;

  const getListItem = () => {
    return (
      <div className="p-2 rounded bg-primary3 w-full flex items-center">
        <div className="h-20 w-20 mr-3 rounded overflow-hidden">
          <img
            src={DocSample}
            alt="ApplicantDocument"
            className="h-full w-full object-contain object-center"
          />
        </div>
        <h3 className="text-base font-bold">
          {article.date}&nbsp;-&nbsp;{article.title}
        </h3>
      </div>
    );
  };

  if (article.external) {
    return (
      <MAnchor className="mb-3" url={article.url} ariaLabel={article.title}>
        {getListItem()}
      </MAnchor>
    );
  }

  return (
    <Link to={getNewsArticleUrl(article.slug)} className="mb-3">
      {getListItem()}
    </Link>
  );
};

export default React.memo(NewsItem);
