import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import { GqlListResponse } from "../models/gql-list-response.model";
import { Article } from "../models/article.model";
import NewsItem from "../components/NewsItem";
import { ROUTE_LINKS } from "../utils/routes";
import SEO from "../components/SEO";

interface Props {}

const query = graphql`
  {
    articles: allMarkdownRemark(
      filter: { fields: { category: { eq: "articles" } } }
      sort: { order: DESC, fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            date
            url
            external
          }
        }
      }
    }
  }
`;

const News: React.FC<Props> = (props: Props) => {
  const queryResult = useStaticQuery(query);
  const rawArticles: GqlListResponse<{ frontmatter: Article }> =
    queryResult.articles;
  const articles = rawArticles.edges.map(f => f.node.frontmatter);

  return (
    <PageWrapper path={ROUTE_LINKS.news}>
      <SEO pageUrl={ROUTE_LINKS.news} />
      <main className="max-w-6xl md:mx-auto p-2.5 pb-12">
        <h1 className="font-bold mb-10 heading lg:text-large text-semiLarge">
          What's in the News
        </h1>
        <div className="flex flex-col w-full">
          {articles.length === 0 && (
            <p className="text-center">No New Updates!</p>
          )}
          {articles.map(article => (
            <NewsItem article={article} key={article.slug} />
          ))}
        </div>
      </main>
    </PageWrapper>
  );
};

export default React.memo(News);
